import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';

import { IconHome, IconSMArrowRight } from 'components/Atom/Icon';
import { H4 } from 'components/Atom/Typo';
import LinkButton from 'components/Atom/Link';

const Component = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  margin-bottom: 24px;

  path {
    fill: ${(props) => props.theme.colors.grey500} !important;
  }

  ${H4} {
    line-height: 1.57;
    font-size: 14px;
  }
`;

const Icon = styled(LinkButton)`
  width: 20px;
  height: 17px;
  margin-right: 8px;
`;

const Depth1 = styled(LinkButton)`
  ${H4} {
    color: ${(props) => props.theme.colors.grey500};
  }
  margin-right: 8px;
  margin-left: 8px;
`;

const Depth2 = styled.div`
  ${H4} {
    color: ${(props) => props.theme.colors.grey600};
  }
  margin-left: 8px;
`;

interface BreadcrumbProps {
  location: {
    pathname: string;
  };
}

const Breadcrumb = ({ location }: BreadcrumbProps) => {
  const [depth1, setDepth1] = useState('');
  const [depth2, setDepth2] = useState('');
  const [root, setRoot] = useState('/');
  useEffect(() => {
    const path_arr = location.pathname.split('/');
    const depth1_list = {
      functions: '기능',
      contact: '문의',
      apply: '적용',
      pricing: '가격',
    };

    const depth2_list = {
      aivory: 'AIVORY 검색 이용 분석',
      environmentAnalysis: '사용 환경 분석',
      usageAnalysis: '웹 사이트 사용 분석',
      uxAnalysis: '사용성(UX) 분석',
      agency: '정부/공공기관',
      company: '기업',
      mall: '온라인 쇼핑몰',
      form: '도입 문의',
      download: '자료실',
    };

    const brd_1 = path_arr[1] as 'functions' | 'contact' | 'apply' | 'pricing';
    const brd_2 = path_arr[2] as
      | 'aivory'
      | 'environmentAnalysis'
      | 'usageAnalysis'
      | 'agency'
      | 'company'
      | 'mall'
      | 'form'
      | 'download';

    switch (brd_1) {
      case 'functions':
        setRoot('/functions/usageAnalysis');
        break;
      case 'contact':
        setRoot('/contact/form');
        break;
      case 'apply':
        setRoot('/apply/agency');
        break;
      case 'pricing':
        setRoot('/pricing');
        break;
      default:
        setRoot('/');
        break;
    }

    setDepth1(depth1_list[brd_1]);
    setDepth2(depth2_list[brd_2] || '');
  }, [location.pathname]);

  return (
    <Component>
      <Icon to="/">
        <IconHome />
      </Icon>

      <IconSMArrowRight />
      <Depth1 to={root}>
        <H4>{depth1}</H4>
      </Depth1>

      {depth2 !== '' ? (
        <>
          <IconSMArrowRight />
          <Depth2>
            <H4>{depth2}</H4>
          </Depth2>
        </>
      ) : null}
    </Component>
  );
};

export default Breadcrumb;
