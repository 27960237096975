import React from 'react';
import styled from '@emotion/styled';
import FunctionsHeader from 'components/Organisms/PageHeader/FunctionsHeader';
import ContactFrom from 'components/Organisms/ContactForm';
import { FluidObject } from 'gatsby-image';
import Navigation from 'components/Organisms/Navigation';
import Footer from 'components/Organisms/Footer';

const Component = styled.div`
  width: 100%;
  height: 100%;
`;

interface ContactFormTemplateProps {
  logoNerdFactoryImg: FluidObject;
  img: FluidObject;
  location: {
    pathname: string;
  };
}

const ContactFormTemplate = ({
  img,
  logoNerdFactoryImg,
  location,
}: ContactFormTemplateProps) => {
  return (
    <Component>
      <Navigation vodaLogoImg={logoNerdFactoryImg} />
      <FunctionsHeader title="도입 문의" img={img} />
      <ContactFrom location={location} />
      <Footer />
    </Component>
  );
};

export default ContactFormTemplate;
