import React from 'react';
import styled from '@emotion/styled';
import ContactFormTemplate from 'components/Templates/ContactFormTemplate';
import { FluidObject } from 'gatsby-image';
import { graphql, withPrefix } from 'gatsby';
import { Helmet } from 'react-helmet';

const Component = styled.div`
  width: 100%;
  height: 100%;
`;

interface FormProps {
  location: {
    pathname: string;
  };
  data: {
    logoNerdFactoryImg: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    topFunctionImg: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    thumbnail: {
      publicURL: string;
    };
  };
}

const Form = ({ data, location }: FormProps) => {
  const title = 'VODA | 문의 | 도입 문의';
  const desc = 'VODA 도입 문의';
  return (
    <Component>
      <Helmet>
        <title>VODA | 문의 | 도입 문의</title>
        <meta name="description" content="VODA 도입 문의" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta httpEquiv="Content-Type" content="text/html;charset=UTF-8" />

        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="<https://voda.nerdfactory.ai/contact/form>"
        />
        <meta property="og:image" content={data.thumbnail.publicURL} />
        <meta property="og:description" content={desc} />
        <meta property="og:site_name" content={title} />
        <meta
          name="naver-site-verification"
          content="062e2e63e40411827d007a80f9f6ca2b16af6702"
        />
        <script src={withPrefix('script.js')} type="text/javascript" />
      </Helmet>
      <ContactFormTemplate
        location={location}
        logoNerdFactoryImg={data.logoNerdFactoryImg.childImageSharp.fluid}
        img={data.topFunctionImg.childImageSharp.fluid}
      />
    </Component>
  );
};

export default Form;

export const queryData = graphql`
  query {
    logoNerdFactoryImg: file(name: { eq: "logo-nerd-factory@3x" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    topFunctionImg: file(name: { eq: "img-top-price@3x" }) {
      childImageSharp {
        fluid(fit: INSIDE, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    thumbnail: file(name: { eq: "thumbnail-1280-720" }) {
      publicURL
    }
  }
`;
