import React from 'react';
import styled from '@emotion/styled';
import { H1 } from 'components/Atom/Typo';
import { FluidObject } from 'gatsby-image';
import Image from 'components/Molecule/Image';

const Component = styled.div`
  width: 100%;
  height: 500px;

  display: flex;
  justify-content: center;
  position: relative;
`;

const TitleWrapper = styled.div`
  max-width: 1440px;
  width: 100%;
  height: 100%;
  position: absolute;
`;

const Title = styled.div`
  position: absolute;
  left: 60px;
  bottom: 40px;
  ${H1} {
    color: white;
    line-height: 1.4;
  }
`;

interface FunctionsHeaderProps {
  title: string;
  img?: FluidObject;
}

const FunctionsHeader = ({ title, img }: FunctionsHeaderProps) => {
  return (
    <Component>
      {img ? <Image img={img} /> : null}
      <TitleWrapper>
        <Title>
          {title.split('\\n').map((element) => {
            return <H1 key={element}>{element}</H1>;
          })}
        </Title>
      </TitleWrapper>
    </Component>
  );
};

export default FunctionsHeader;
